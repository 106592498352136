import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;


async function getVotes() {
  try {
    const response = await axios.get(`${API_URL}/calendar`);
    return response.data;
  }
  catch (error) {
    console.error(error);
  }
}


async function getTotal() {
  try {
    const response = await axios.get(`${API_URL}/total`);
    return response.data;
  }
  catch (error) {
    console.error(error);
  }
}


async function addVotes(vote, player) {
  try {
    const response = await axios.post(`${API_URL}/addVote`, { vote: vote, player: player });
    return response.data;
  }
  catch (error) {
    console.error(error);
  }
}


async function getNumToken(player) {
  try {
    const response = await axios.get(`${API_URL}/tokenLeft/${player}`);
    return response.data;
  }
  catch (error) {
    console.error(error);
  }
}


export { getVotes, getTotal, addVotes, getNumToken }