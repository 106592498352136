import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { basePoints, maxPoints, categories, players } from '../data/constant';
import { addVotes, getNumToken } from '../API/Votes';

function Vote({ player }) {
  const [point, setPoint] = useState(null);
  const [voteObj, setVoteObj] = useState([]);
  const [isVote, setIsVote] = useState(false);
  const [nextVote, setNextVote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isVoting, setIsVoting] = useState(false);

  useEffect(() => {
    async function init() {
      await getNumToken(player).then((res) => {
        setPoint(basePoints - res.tokenLeft);
        if (res.tokenLeft >= 3) {
          setIsVote(true);
          const today = new Date();
          const nextVote = new Date();
          nextVote.setDate(today.getDate() + 2);
          nextVote.setHours(0, 0, 0, 0);
          setNextVote(`${24 - today.getHours()}h et ${60 - today.getMinutes()}min`);
        }
      })
      var playerTmp = []
      players.map((player) => {
        var tmp = [];
        categories.map((categorie) => {
          tmp.push({ categorie: categorie, vote: 0 });
        });
        playerTmp.push({ player: player, categories: tmp });
        setVoteObj(playerTmp);
      })
      setLoading(false);
    }
    init();
  }, []);


  const handleVote = (player, categorie, vote) => {
    var tmp = voteObj;
    tmp.map((playerTmp) => {
      if (playerTmp.player === player) {
        playerTmp.categories.map((categorieTmp) => {
          if (categorieTmp.categorie === categorie && categorieTmp.vote + vote <= maxPoints && categorieTmp.vote + vote >= 0) {
            categorieTmp.vote += vote;
            setVoteObj(tmp);
            setPoint(point - vote);
          }
        })
      }
    })
  }


  const handleComment = (player, comment) => {
    var tmp = voteObj;
    tmp.map((playerTmp) => {
      if (playerTmp.player === player) {
        playerTmp.comment = comment;
        setVoteObj(tmp);
      }
    })
  }

  const handleValidate = async () => {
    setIsVoting(true);
    await addVotes(voteObj, player);
    setIsVoting(false);
    window.location.reload();
  }



  return (
    loading ? <h1>Chargement...</h1> :
      <Container>
        {isVote ? (
          <VotedDiv>
            <h3>Vous avez déjà voté</h3>
            <h3>Vous pouvez voter à nouveau dans {nextVote}</h3>
          </VotedDiv>
        ) : (
          <>
            <h3>Vous votez en tant que {player}</h3>
            <h3>Vous avez {point} points</h3>
            <VoteDiv>
              {voteObj.map((playerObj) => {
                if (playerObj.player === player) return null;
                return (
                  <PlayerDiv>
                    <PlayerTitle>{playerObj.player}</PlayerTitle>
                    <CategoriesDiv>
                      {playerObj.categories.map((categorie) => (
                        <LineCat>
                          <h4>{categorie.categorie}</h4>
                          <ButtonDiv>
                            <PlayerButton onClick={() => { handleVote(playerObj.player, categorie.categorie, 1) }} disabled={categorie.vote === 1 || point === 0} >+</PlayerButton>
                            <p>{categorie.vote}</p>
                            <PlayerButton onClick={() => { handleVote(playerObj.player, categorie.categorie, -1) }} disabled={categorie.vote === 0}>-</PlayerButton>
                          </ButtonDiv>
                        </LineCat>
                      ))}
                      <Title>Commentaire</Title>
                      <textarea style={{ maxWidth: '100%', minWidth: "100%" }} onChange={(e) => { handleComment(playerObj.player, e.target.value) }}></textarea>
                    </CategoriesDiv>
                  </PlayerDiv>
                )
              }
              )}
            </VoteDiv>
            <ValidateButton onClick={() => { handleValidate() }} disabled={isVoting}>Valider</ValidateButton>
          </>
        )}
      </Container>
  );
}
const VotedDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
`



const Title = styled.h1`
  font-size: 16px;
  text-align: center;
  margin: 10px 0;
`

const ValidateButton = styled.button`
  background-color: #4ab122;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-top: 40px;
  width: 100%;
  font-size: 20px;
  cursor: pointer;
  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
`

const LineCat = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const PlayerTitle = styled.h2`
  text-decoration: underline;
  margin: 0;
  padding: 0;
`

const CategoriesDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const PlayerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 5px;
  gap: 10px;
  width: 100%;
  padding: 10px;
`

const VoteDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;
`

const Container = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: space-between;
  width: 85%;
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const PlayerButton = styled.button`
  background-color: #282c34;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 16px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &:disabled {
    background-color: #282c34;
    color: grey;
    border: 1px solid grey;
    cursor: not-allowed;
  }
`

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export default Vote;