import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Vote from './Vote';
import Calendrier from './Calendrier';

function Home({ player }) {
  const [page, setPage] = useState(null);

  return (
    <Container>
      <Header>
        <h1>Calendrier du aigri</h1>
        {page !== null && <HomeButton onClick={() => { setPage(null) }}>Home</HomeButton>}
      </Header>
      <Body>
        {page === null &&
          <ButtonDiv>
            <PlayerButton color="#39a50e" onClick={() => { setPage("vote") }}>Vote</PlayerButton>
            <PlayerButton color="#0e3ea5" onClick={() => { setPage("calendrier") }}>Calendrier</PlayerButton>
          </ButtonDiv>
        }
        {page === "calendrier" && <Calendrier />}
        {page === "vote" && <Vote player={player} />}
      </Body>
      <Footer>
        <p>fait avec ❤️ par Valoon</p>
      </Footer>
    </Container>
  );
}

const HomeButton = styled.button`
  background-color: #af2020;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
`


const Container = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: space-between;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 80%;
`

const PlayerButton = styled.button`
  background-color: ${props => props.color};
  color: white;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  font-size: 20px;
  border: none;
  font-weight: bold;
`


export default Home;