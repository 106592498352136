import './App.css';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import SelectPlayer from './page/SelectPlayer';
import Vote from './page/Vote';
import Home from './page/Home';

function App() {
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const player = localStorage.getItem('player');
    setPlayer(player);
  }, []);

  return (
    <Container>
      {player === null ? (<SelectPlayer />) : (<Home player={player} />)}
    </Container>
  );
}

const VotedDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const Container = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: space-between;
  max-width: 100vw;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`


export default App;