import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { getVotes } from '../API/Votes';


function Calendrier() {
  const [votes, setVotes] = useState({});

  useEffect(() => {
    getVotes().then((res) => {
      setVotes(res);
    })
  }, []);

  function getTotal(day) {
    var total = {
      "votes": 0,
      "commentaires": 0,
    };
    Object.keys(votes[day]).map((user) => {
      Object.keys(votes[day][user]).map((categories) => {
        if (categories === "commentaires")
          total.commentaires += parseInt(votes[day][user][categories].length);
        else
          total.votes += parseInt(votes[day][user][categories]);
      })
    })
    return total;
  }

  return (
    <Container>
      <h1>Calendrier</h1>
      <CalendrierDiv>
        {Object.keys(votes).map((day) => {
          const total = getTotal(day)
          return (
            <DayDiv>
              <HeaderCal>
                <h2>{day}</h2>
                <StatDiv>
                  <h4>{total.votes} votes</h4>
                  <h4>{total.commentaires} commentaires</h4>
                </StatDiv>
              </HeaderCal>
              {total.votes > 0 && <VoteTabe votes={votes[day]} />}
              {total.commentaires > 0 && <Commentaire votes={votes[day]} />}
            </DayDiv>
          )
        })}
      </CalendrierDiv>
    </Container>
  );
}

const CalTable = styled.table`
  width: 100%;
  border-spacing: 0px;
`
const HeaderValTable = styled.td`
  text-align: center;
border-bottom: 1px solid white;
`

const BodyRowTable = styled.tr`
`

const StatDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const HeaderCal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
`

const CalendrierDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;
`


const DayDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  gap: 10px;
`

function VoteTabe({ votes }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ComAndVoteDiv>
      <Header>
        <h3>Votes</h3>
        <OpenButton onClick={() => { setIsOpen(!isOpen) }} color={isOpen ? "#9e1e1e" : "#2e8314"}>{isOpen ? "Close" : "Open"}</OpenButton>
      </Header>
      <CalTable>
        {isOpen &&
          <>
            <tr>
              <HeaderValTable></HeaderValTable>
              <HeaderValTable>Aigritude</HeaderValTable>
              <HeaderValTable>Retard</HeaderValTable>
              <HeaderValTable>Flop</HeaderValTable>
            </tr>
            {Object.keys(votes).map((user) => {
              return (
                <BodyRowTable>
                  <HeaderValTable>{user}</HeaderValTable>
                  {Object.keys(votes[user]).map((cat) => {
                    if (cat !== "commentaires")
                      return <HeaderValTable>{votes[user][cat]}</HeaderValTable>
                  })}
                </BodyRowTable>
              )
            })}
          </>
        }
      </CalTable>
    </ComAndVoteDiv>
  );
}

const ComAndVoteDiv = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`


const OpenButton = styled.button`
  border: none;
  background-color: ${props => props.color};
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
`

function Commentaire({ votes }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ComAndVoteDiv>
      <Header>
        <h3>Commentaires</h3>
        <OpenButton onClick={() => { setIsOpen(!isOpen) }} color={isOpen ? "#9e1e1e" : "#2e8314"}>{isOpen ? "Close" : "Open"}</OpenButton>
      </Header>
      {isOpen &&
        <ComTable>
          {Object.keys(votes).map((user) => {
            if (votes[user].commentaires.length > 0)
              return (
                <ComDiv>
                  <p>{user} :</p>
                  {votes[user].commentaires.map((com) => {
                    return (
                      <Com>{com}</Com>
                    )
                  })}
                </ComDiv>
              )
          })}
        </ComTable>
      }
    </ComAndVoteDiv>
  );
}

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const ComTable = styled.div`
  width: 100%;
`

const Com = styled.p`
  border-bottom: 1px solid #ffffff47;
  font-size: 12px;
  text-align: center;
`

const ComDiv = styled.div`
  width: 100%;
  border: 1px solid white;
  padding: 5px 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export default Calendrier;