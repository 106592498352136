export const players = [
  "Valentin",
  "Adam",
  "Martin",
  "Nathaniael"
]


export const categories = [
  "Aigritude",
  "Retard",
  "Flop",
]

export const maxPoints = 2;

export const basePoints = 3;
