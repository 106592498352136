import styled from 'styled-components';
import { useState } from 'react';
import { players } from '../data/constant';



function SelectPlayer() {

  function handleSelecty(player) {
    localStorage.setItem('player', player);
    window.location.reload();
  }

  return (
    <Container>
      <Header>
        <h1>Calendrier du aigri</h1>
      </Header>
      <Body>
        <h2>Vous etes ?</h2>
        <ButtonDiv>
          {players.map((player) => (
            <PlayerButton onClick={() => { handleSelecty(player) }}>{player}</PlayerButton>
          ))}
        </ButtonDiv>
      </Body>
      <Footer>
        <p>fait avec ❤️ par Valoon</p>
      </Footer>
    </Container>
  );
}


const Container = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: space-between;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PlayerButton = styled.button`
  background-color: #282c34;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
`

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

export default SelectPlayer;